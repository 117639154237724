<template>

    <div>
  
      <sms-list-add-new
        :is-add-new-sms-sidebar-active.sync="isAddNewSmsSidebarActive"
        :type-options="typeOptions"
        :variant-options="variantOptions"
        :clear-sms-data="clearSmsData"
        :smsData="smsData"
        @add-sms="addSms"
        @update-sms="updateSms"
        :sms-info="getSmsInfo"
      />
  
      <!-- Filters -->
      <smses-list-filters
        :type-filter.sync="typeFilter"
        :variant-filter.sync="variantFilter"
        :status-filter.sync="statusFilter"
        :range-filter.sync="rangeFilter"
        :type-options="typeOptions"
        :variant-options="variantOptions"
        :range-options="rangeOptions"
        :status-options="statusOptions"
      />
  
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
  
        <div class="m-2">
  
          <!-- Table Top -->
          <b-row>
  
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
  
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <!-- <b-button
                  variant="primary"
                  tag="router-link"
                  :to="{ name: 'apps-smses-add' }"
                  class="mr-2"
                >
                  <span class="text-nowrap">Add Sms</span>
                </b-button> -->
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="isAddNewSmsSidebarActive = true"
                >
                  <span class="text-nowrap">Send Sms</span>
                </b-button>
                <!-- <b-button
                  variant="outline-primary"
                  class="mr-1"
                  @click="genSmsReport"
                >
                  <span class="text-nowrap">Export to *.CSV</span>
                </b-button> -->
                <!-- <b-button
                  variant="outline-primary"
                  @click="genSmsReport"
                >
                  <span class="text-nowrap">Export to *.Excel</span>
                </b-button> -->
              </div>
            </b-col>
          </b-row>
  
        </div>
  
        <b-table
          ref="refSmsListTable"
          class="position-relative"
          :items="getSmses"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>
  
        <!-- Column: Role -->
        <template #cell(type)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="resolveTypeRoleIcon(data.item.type)"
                size="18"
                class="mr-50"
                :class="`text-primary`"
              />
              <span class="font-weight-bold align-text-top text-capitalize">{{ resolveTypeVariant(data.item.type) }} </span>
              <b-badge v-if="data.item.variant" :variant="`${resolveSmsSmsVariant(data.item.variant)}`">{{ resolveSmsVariant(data.item.variant) }}</b-badge>
              <div class=" small" v-if="data.item.type == 'scheduled'">{{ data.item._scheduled }} </div>
            </div>
          </template>

          <template #cell(contacts)="data">
            <div v-if="data.item.contacts.length > 0" class="text-nowrap text-primary">
                <app-collapse>
                    <app-collapse-item :icon="false" title="">
                        <template #header>
                            <span class="font-weight-bold">{{ data.item.contacts.length }} Contacts</span>
                        </template>
                        <b-badge
                            pill
                            tag="a"
                            :href="`tel:${contact.contact.countryCode+contact.contact.tel}`"
                            :variant="`primary`"
                            v-for="contact in data.item.contacts"
                            :key="contact._id"
                            class="text-capitalize mr-1 cursor-pointer"
                        >
                            {{ contact.name }}
                        </b-badge>
                    </app-collapse-item>
                </app-collapse>    
              <!-- <b-badge
                pill
                :variant="`primary`"
                v-for="contact in data.item.contacts"
                :key="contact._id"
                class="text-capitalize mr-1 cursor-pointer"
              >
                {{ contact.name }}
              </b-badge> -->
              <!-- {{ data.item.contacts.length }} Contacts -->
            </div>
          </template>


        <template #cell(groups)="data">
          <b-badge
              v-for="group in data.item.groups"
              pill
              :key="group.name"
              :variant="`primary`"
              class="text-capitalize mr-1"
            >
              {{ group.name }}
            </b-badge>

            <b-badge
              v-for="appointment in data.item.appointments"
              pill
              :key="appointment.name"
              :variant="`secondary`"
              class="text-capitalize mr-1"
            >
              {{ appointment.name }}
            </b-badge>
        </template>


        <template #cell(details)="data">
          <p class="mb-0 font-weight-bold">Parts: <span class="text-primary"> {{ data.item.parts }}/5 </span> </p>
          <p class="mb-0 font-weight-bold">Length: <span class="text-primary"> {{ data.item.length }} </span> </p>
          <p class="mb-0 font-weight-bold">Total Sms: <span class="text-primary"> {{ data.item.totSms }} </span> </p>
        </template>
  
          <!-- Column: Role -->
          <template #cell(sms)="data">
            <div class="text-nowrap">
              <!-- <feather-icon
                :icon="resolveSmsRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                
              /> -->
              <b-badge :variant="`${resolveSmsSmsVariant(data.item.sms)}`">{{ resolveSmsVariant(data.item.sms) }}</b-badge>
              <!-- <span class="align-text-top text-capitalize">{{ data.item.role }}</span> -->
            </div>
          </template>
  
          <!-- Column: DOB -->
          <template #cell(_smsDate)="data">
            <div class="text-dark">
              {{   data.item._smsDate }}
            </div>
          </template>
  
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveSmsStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>


          <template #cell(createdAt)="data">
            {{ data.item._createdAt }}
            <div style="display:none" class="text-dark">
              {{  data.item.createdAt  }}
            </div>
          </template>
  
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
  
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="copy(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Copy</span>
              </b-dropdown-item>
  
              <!-- <b-dropdown-item @click="editSms(data.item)" v-if="data.item.status == 'pending'">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item> -->
  
              <b-dropdown-item @click="confirmText(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
  
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
  
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
  
              <b-pagination
                v-model="currentPage"
                :total-rows="totalSmses+' - '+totalAmount"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
  
            </b-col>
  
          </b-row>
        </div>
      </b-card>
  
  
      <!--  -->
      <b-modal
        id="cancel-modal"
        :title="`Cancel Sms ${sms ? ': '+sms.type+' : '+sms.amount : ''}`"
        ok-only
        ok-title="Close"
      > 
      <!-- Body -->
      <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
          <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Reason -->
              <validation-provider
                      #default="validationContext"
                      name="Reason"
                      rules="required"
                    >
                <b-form-group
                    label="Reason for cancelation"
                    label-for="reason"
                  >
                    <b-form-textarea
                      id="reason"
                      v-model="sms.reason"
                      trim
                      placeholder="Reason"
                      :state="getValidationState(validationContext)"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button type="submit">
                Submit
              </b-button>
            </b-form>
        </validation-observer>
        <!-- <b-card-text>
          <h5>Check First Paragraph</h5>
          Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie.
          Brownie carrot cake candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake liquorice.
        </b-card-text> -->
        
      </b-modal>
  
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BForm, BFormInput, BFormTextarea, BFormGroup, BButton, BTable, BMedia, BAvatar, BLink, BModal, VBModal,
    BBadge, BDropdown, BDropdownItem, BPagination, BFormInvalidFeedback
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import router from '@/router'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import SmsesListFilters from './SmsListFilters.vue'
  import useSmsList from './useSmsList'
  import smsStoreModule from '../smsStoreModule'
  import memberStoreModule from '../../../apps/membership/memberStoreModule'
  import calendarStoreModule from '../../../apps/calendar/calendarStoreModule'
  import SmsListAddNew from '../sms-add-new/SmsListAddNew.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import Swal from 'sweetalert2'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  
  export default {
    components: {
      SmsesListFilters,
      SmsListAddNew,
      BCard,
      BRow,
      BForm,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BModal, 
      VBModal,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback,
  
      vSelect,
      AppCollapse,
      AppCollapseItem
    },
    
    directives: {
      Ripple,
    },
    data() {
      return {
        required,
      }
    },
    setup() {
  
      const blankSmsData = {
        id: '',
        type: '',
        amount: '',
        reason: ''
      }
  
      const sms = ref(JSON.parse(JSON.stringify(blankSmsData)))
  
      const send = (data) => {
        //
        sms.value.id = data._id
        sms.value.type = data.type
        sms.value.amount = data.amount
  
      }
  
      const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'app-sms'
      const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
      const CALENDAR_STORE_MODULE_NAME = 'calendar'
  
      // Register module
      if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, smsStoreModule)
      if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
      if (!store.hasModule(CALENDAR_STORE_MODULE_NAME)) store.registerModule(CALENDAR_STORE_MODULE_NAME, calendarStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
        if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
        if (store.hasModule(CALENDAR_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_STORE_MODULE_NAME)
      })
  
      const smsLocal = ref(JSON.parse(JSON.stringify(sms)))
      const resetSmsLocal = () => {
        smsLocal.value = JSON.parse(JSON.stringify(sms))
      }
  
      const {
          refFormObserver,
          getValidationState,
          resetForm,
          clearForm,
      } = formValidation(resetSmsLocal, sms)
  
      const confirmText = (data) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            
            delSms(data)
          }
        })
      }
  
      const statusOptions = [
        { label: 'Sent', value: 'sent' },
        { label: 'Pending', value: 'pending' },
        { label: 'Cancelled', value: 'cancelled' },
      ]
  
      const variantOptions = [
        { label: 'Receipt', value: 'receipt' },
        { label: 'Birthday', value: 'birthday' },
        { label: 'Event', value: 'event' },
        { label: 'Task', value: 'task' }
      ]
  
      const typeOptions = [
        { label: 'Instant', value: 'instant' },
        { label: 'Scheduled', value: 'scheduled' },
        { label: 'Reminder', value: 'reminder' }
      ]
  
      const rangeOptions = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        // { label: 'This Week', value: 'this week' },
        // { label: 'Previous Week', value: 'previous week' },
        { label: 'This Month', value: 'this month' },
        { label: 'Previous Month', value: 'previous month' },
        { label: 'This Year', value: 'this year' },
        { label: 'Previous Year', value: 'previous year' }
      ]
  
      const {
        getSmses,
        fetchSmses,
        fetchMembers,
        fetchGroups,
        fetchAppointments,
        tableColumns,
        perPage,
        currentPage,
        totalSmses,
        totalAmount,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refSmsListTable,
        refetchData,
  
        // UI
        resolveSmsStatusVariant,
        resolveSmsVariant,
        resolveSmsSmsVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
        resolveRangeVariant,
  
        //
        isAddNewSmsSidebarActive,
        clearSmsData,
        editSms,
        delSms,
        addSms,
        updateSms,
        approveSms,
        smsData,
        genSmsReport,
  
        // Extra Filters
        typeFilter,
        daybornFilter,
        variantFilter,
        serviceFilter,
        statusFilter,
        rangeFilter,
        totalSmsAmount,
        cancelSms,
        smsInfo,
        getSmsInfo
      } = useSmsList()
  
      fetchSmses()
  
      fetchMembers()

      fetchGroups()

      fetchAppointments()

      smsInfo()
  
      
      const onSubmit = () => {
        cancelSms(sms.value)
        clearForm.value()
      }

      const copy = (data) => {
        //
        smsData.value = [data].map(x=>({ contacts: x.contacts, message: x.message, groups: x.groups, scheduled: x.scheduled }))[0]
        //
        isAddNewSmsSidebarActive.value = true
      }
  
      // 
      setTimeout(()=>{
        
        // edit request
        if (router.currentRoute.params.id) {
          //
          const data = getSmses.value.find(rec => rec.id == router.currentRoute.params.id)
          editSms(data)
        }
  
        if (router.currentRoute.params.add) {
          //
          isAddNewSmsSidebarActive.value = true
        }
  
        if (router.currentRoute.params.type && router.currentRoute.params.date && router.currentRoute.params.status) {
          //
          typeFilter.value = router.currentRoute.params.type
          statusFilter.value = router.currentRoute.params.status
          searchQuery.value = router.currentRoute.params.date
          }
        }, 500)
    
      return {
  
        // Sidebar
        isAddNewSmsSidebarActive,
  
        getSmses,
        fetchSmses,
        tableColumns,
        perPage,
        currentPage,
        totalSmses,
        totalAmount,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refSmsListTable,
        refetchData,
        genSmsReport,
  
        // Filter
        avatarText,
  
        // UI
        resolveSmsStatusVariant,
        resolveSmsVariant,
        resolveSmsSmsVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
        resolveRangeVariant,
  
        typeOptions,
        variantOptions,
        statusOptions,
        rangeOptions,
  
        //
        clearSmsData,
        editSms,
        delSms,
        addSms,
        updateSms,
        approveSms,
        smsData,
        sms,
        send,
        
        //
        refFormObserver,
        getValidationState,
        resetForm,
        clearForm,
  
        // Extra Filters
        typeFilter,
        variantFilter,
        statusFilter,
        rangeFilter,
        totalSmsAmount,
        confirmText,
        onSubmit,
        fetchGroups,
        smsInfo,
        getSmsInfo,
        copy
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  