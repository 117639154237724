import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSmsesList() {
  // Use toast
  const toast = useToast()

  const refSmsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'type', sortable: true },
    { key: 'contacts',  sortable: true, thStyle: { width: "10%" }, },
    // { key: 'variant',  sortable: false },
    { key: 'groups', label: 'groups / appointments', sortable: true },
    { key: 'message', sortable: false, thStyle: { width: "22%" }, },
    { key: 'details', sortable: true },
    // { key: 'by', sortable: true },
    { key: 'createdAt', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(5)
  const totalSmses = ref(0)
  const totalAmount = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)
  const typeFilter = ref (null)
  const variantFilter = ref(null)
  const statusFilter = ref(null)
  const rangeFilter = ref(null)
  const totalSmsAmount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refSmsListTable.value ? refSmsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSmses.value,
    }
  })

  const refetchData = () => {
    refSmsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, typeFilter, variantFilter, statusFilter, rangeFilter], () => {
    refetchData()
  })

  const getSmses = computed(() => {
   const response =  store.getters['app-sms/getSmses']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      type: typeFilter.value,
      variant: variantFilter.value,
      status: statusFilter.value,
      range: rangeFilter.value,
    })

    const { smses, total } = response
    totalSmses.value = total

    // console.log(total)
    return smses
  })

  const getAllSmses = computed(() => {
    const response =  store.getters['app-sms/getAllSmses']
 
     const { smses, total } = response
     totalSmses.value = total
    //  totalAmount.value = totalAmount
 
     return smses
   })

  const fetchSmses = (ctx, callback) => {
    store
      .dispatch('app-sms/fetchSmses')
      .then(response => {
        // const { smses, total } = response.data

        // callback(smses)
        // totalSmses.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Smses',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching smses list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const fetchGroups = (ctx, callback) => {
    store
      .dispatch('calendar/fetchGroups')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching smses list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const fetchAppointments = (ctx, callback) => {
    store
      .dispatch('calendar/fetchAppointments')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching smses list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const smsInfo = (ctx, callback) => {
    store
      .dispatch('app-sms/fetchSmsInfo')
      .then(response => {
        const { smsSender, sms } = response.data

        // callback(response.data)
        // totalSmses.value = total
      })
      .catch((err) => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Sms Sender Data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getSmsInfo = computed(() => {
    const response =  store.getters['app-sms/getSmsInfo']
    //  totalAmount.value = totalAmount
 
     return response
   })


  const blankSmsData = {
    contacts: [],
    groups: [],
    appointments: [],
    message: '',
    scheduled: null,
  }

  const smsData = ref(JSON.parse(JSON.stringify(blankSmsData)))

  const clearSmsData = () => {
    smsData.value = JSON.parse(JSON.stringify(blankSmsData))
  }

  const editSms = (smsEvent) => {
    
    smsData.value = JSON.parse(JSON.stringify(Object.assign(smsEvent)))
    // const { smsData } = data
    // emit('update:is-add-new-user-sidebar-active', true)
    isAddNewSmsSidebarActive.value = true
    // console.log(smsEvent)

    smsData.value = grabDataFromEditSms(smsEvent)
  }

  const grabDataFromEditSms = smsData => {

    const {
      id,
      contacts,
      groups,
      message,
      scheduled
    } = smsData

    return {
      id,
      contacts,
      groups,
      message,
      scheduled
    }
  }

  // ------------------------------------------------
  // addSms
  // ------------------------------------------------
  const addSms = smsEvent => {

    // set to all members
    smsEvent.contacts = smsEvent.contacts.length == 0 && smsEvent.groups.length == 0 && smsEvent.appointments.length == 0 ? store.getters['app-member/getAllMembers'].members.map(m=>({ name: m.fullName, contact: m.contact1 })) : smsEvent.contacts

    store.dispatch('app-sms/addSms', { sms: smsEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define

      console.log(response)
      
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      fetchSmses()
      getSmsInfo()

      // return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        // return error
    })
  }

  // ------------------------------------------------
  // updateSms
  // ------------------------------------------------
  const updateSms = smsEvent => {
    
    store.dispatch('app-sms/updateSms', { sms: smsEvent }).then(response => {
      
      fetchSmses()
      // close sidebar
      isAddNewSmsSidebarActive.value = false

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
    })
  }

  const genSmsReport =  () => {
    //
    store.dispatch('app-sms/genSmsReport').then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchSmses()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }

  const delSms = (smsEvent) => {
    
    store.dispatch('app-sms/delSms', { sms: smsEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchSmses()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const approveSms = receiptEvent => {
    store.dispatch('app-sms/approveSms', { sms: receiptEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchSmses()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  const cancelSms = (smsEvent) => {
    
    store.dispatch('app-sms/cancelSms', { sms: smsEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchSmses()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    })
  }

  const isAddNewSmsSidebarActive = ref(false)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveSmsSmsVariant =  sms => {
    if (sms === 'receipt') return 'primary'
    if (sms === 'birthday') return 'info'
    if (sms === 'event') return 'secondary'
    if (sms === 'task') return 'success'
    return 'primary'
  }

  const resolveSmsStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'sent') return 'success'
    if (status === 'cancelled') return 'danger'
    return 'secondary'
  }

  const resolveSmsVariant = sms => {
    if (sms === 'receipt') return 'Receipt'
    if (sms === 'birthday') return 'Birthday'
    if (sms === 'event') return 'Event'
    if (sms === 'task') return 'Task'
  }

  const resolveRangeVariant = type => {
    if (type === 'today') return 'Today'
    if (type === 'yesterday') return 'Yesterday'
    if (type === 'this month') return 'This Month'
    if (type === 'previous month') return 'Previous Month'
    if (type === 'this year') return 'This Year'
    if (type === 'previous year') return 'Previous Year'
  }

  const resolveTypeVariant = type => {
    if (type === 'instant') return 'Instant'
    if (type === 'scheduled') return 'Scheduled'
    if (type === 'reminder') return 'Reminder'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'instant') return 'SendIcon'
    if (role === 'scheduled') return 'CalendarIcon'
    if (role === 'reminder') return 'RotateCcwIcon'
    return ''
  }

  return {
    getSmses,
    getAllSmses,
    fetchSmses,
    fetchMembers,
    fetchGroups,
    fetchAppointments,
    tableColumns,
    perPage,
    currentPage,
    totalSmses,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSmsListTable,
    refetchData,
    totalAmount,

    resolveSmsStatusVariant,
    resolveSmsVariant,
    resolveSmsSmsVariant,
    resolveTypeVariant,
    resolveTypeRoleIcon,
    resolveRangeVariant,

    //
    isAddNewSmsSidebarActive,
    clearSmsData,
    editSms,
    delSms,
    addSms,
    updateSms,
    approveSms,
    smsData,
    cancelSms,
    genSmsReport,

    // Extra Filters
    typeFilter,
    variantFilter,
    statusFilter,
    rangeFilter,
    totalSmsAmount,
    smsInfo,
    getSmsInfo
  }
}
