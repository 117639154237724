import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useSmsListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // smsLocal
  // ------------------------------------------------
  const smsLocal = ref(JSON.parse(JSON.stringify(props.smsData.value)))
  const resetSmsLocal = () => {
    smsLocal.value = JSON.parse(JSON.stringify(props.smsData.value))
  }
  watch(props.smsData, () => {
    resetSmsLocal()
  })

  // ------------------------------------------------
  // isAddNewSmsSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewSmsSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  

  const onSubmit = () => {
    const smsData = JSON.parse(JSON.stringify(smsLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.smsData.value.id) emit('update-sms', smsData.value)
    else emit('add-sms', smsData.value)

    // Close sidebar
    // emit('update:is-add-new-sms-sidebar-active', false)
  }

  const groupsOptions = computed(() => store.state.calendar.groups)
  const appointmentOptions = computed(() => store.state.calendar.appointments)


  /* eslint-enable global-require */

  return {
    smsLocal,
    resetSmsLocal,
    onSubmit,
    groupsOptions,
    appointmentOptions
  }
}

