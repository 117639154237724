import axios from '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Funeral',
      },
      {
        color: 'primary',
        label: 'Wedding',
      },
      {
        color: 'secondary',
        label: 'Other',
      },
      {
        color: 'warning',
        label: 'Christening',
      },
      {
        color: 'info',
        label: 'Revival',
      },
      {
        color: 'secondary',
        label: 'Retreat',
      },
      {
        color: 'success',
        label: 'Service',
      }
    ],
    selectedCalendars: ['Funeral', 'Wedding', 'Christening', 'Revival', 'Retreat', 'Other', 'Service'],
    events: [],
    groups: [],
    appointments: []
  },
  getters: {
    getEvents: (state) => (params) => {
      // Get requested calendars as Array
      const calendars = params.calendars

      return state.events.filter(event => calendars.includes(event.extendedProps.calendar))
    },
  },
  mutations: {
    updateEvents(state, events) {
      state.events = events
    },
    updateGroups(state, groups) {
      //
      state.groups = groups
    },
    updateAppointments:  (state, appointments) => {
      //
      state.appointments = appointments
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents({ commit }) {
      return new Promise((resolve, reject) => {
       axios
          // .get('/apps/calendar/events', {
          .get(`/events/${userData.church}`)
          .then(response => {
            
            commit('updateEvents', response.data)
            
            resolve(response)
          
          })
          .catch(error => {
            
            // return error
            reject(error)
          })
      })
    },
    fetchGroups({ commit }) {
      // return new Promise((resolve, reject) => {
       axios
          // .get('/apps/calendar/events', {
          .get(`/groups/${userData.church}`)
          .then(response => {
            
            commit('updateGroups', response.data.map(gp => ({ name: gp.group, value: gp.group, members: gp.members })))
            
            // resolve(response)
          
          })
          .catch(error => {
            
            return error
            // reject(error)
          })
      // })
    },
    fetchAppointments({ commit }) {
      // return new Promise((resolve, reject) => {
        axios
          .get(`/appointments/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateAppointments', response.data.map(ap => ({ name: ap.appointment, value: ap.appointment, members: ap.members })))
            // resolve(response)
          
          })
          .catch(error => { 
            
            return error
            // reject(error)

          })
      // })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/event', { ...event, church: userData.church })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/event/${userData.church}/${event.id}`, { ...event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/event/${userData.church}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
